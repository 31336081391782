import React, { useState, useContext } from "react";
import { Col, Row, Card, CardBody, Button, Spinner, Alert } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Modal from "./Modal";
import { authContext } from "../../../contexts/AuthContext";
import { POST, createIntent } from "../../../utils/api";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#87BBFD",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
  },
};

const CheckoutForm = ({ creditsCnt }) => {
  const { auth, setAuthData } = useContext(authContext);
  const stripe = useStripe();
  const elements = useElements();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Set loading while interacting with API

  const handleSubmit = async (event) => {
    setError("");
    setSuccess(false);
    event.preventDefault();
    if (loading) return;

    setLoading(true);

    createIntent(creditsCnt, auth.token)
      .then((client_secret) => {
        return stripe.confirmCardPayment(client_secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            // billing_details: {
            //   name: formData.fname + " " + formData.lname,
            // },
          },
        });
      })
      .then((res) => {
        if (res.error || res.paymentIntent.status !== "succeeded") {
          return Promise.reject({ message: res.error.message });
        }
        return POST(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/user/add_credit`,
          { creditsCnt },
          auth.token
        );
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status === "success") {
          setSuccess(true);
          setAuthData({
            ...auth,
            creditsRemaining:
              parseInt(auth.creditsRemaining) + parseInt(creditsCnt),
          });
        }
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="stripe-form mt-2">
      {!!error && (
        <Alert color="danger" style={{ maxWidth: 500 }}>
          {error}
        </Alert>
      )}
      <Modal
        color="success"
        title="Success!"
        btn="Success"
        message="Thank you, your payment was successful."
        isOpen={success}
        onClick={() => {
          setSuccess(false);
        }}
      />
      <div>
        <label htmlFor="cardNumber">Card Number</label>
        <CardNumberElement id="cardNumber" options={CARD_OPTIONS} />
      </div>
      <div>
        <label htmlFor="expiry">Card Expiration</label>
        <CardExpiryElement id="expiry" options={CARD_OPTIONS} />
      </div>
      <div>
        <label htmlFor="cvc">CVC</label>
        <CardCvcElement id="cvc" options={CARD_OPTIONS} />
      </div>
      <Button color="matched" type="submit" disabled={!stripe || loading}>
        {loading && <Spinner color="primary" />}
        {loading === false && "Pay Now"}
      </Button>
    </form>
  );
};

export default ({ creditsCnt }) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Payment</h3>
          <h3 className="page-subhead subhead">Pay securely by credit card</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={9}>
          <Card>
            <CardBody>
              <img
                src={require("../../../shared/img/card.png")}
                style={{ maxHeight: 50, width: "auto" }}
                alt="card png"
              />
              <Elements stripe={stripePromise}>
                <CheckoutForm creditsCnt={creditsCnt} />
              </Elements>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
