import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";

export default ({ data }) => {
  const [showOrg, setShowOriginal] = useState(false);
  return (
    <Row style={styles.container}>
      {!showOrg && <div>{data.corrected}</div>}
      {showOrg && <div>{data.original}</div>}
      <Button
        color="secondary"
        onClick={() => setShowOriginal(!showOrg)}
        style={styles.btn}
      >
        See {showOrg ? "Corrected" : "Original"}
      </Button>
    </Row>
  );
};

const styles = {
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
    borderBottom: "1px solid green",
    padding: "8px 15px",
  },
  btn: {
    padding: "5px 10px",
    fontSize: 13,
    textTransform: "none",
  },
};
