export const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const getCosePerCredit = (creditsCnt) => {
  let costpercredit = 0;
  if (creditsCnt < 31) costpercredit = 0;
  else if (creditsCnt < 1001) costpercredit = 5;
  else if (creditsCnt < 3001) costpercredit = 4.5;
  else if (creditsCnt < 5001) costpercredit = 4;
  else if (creditsCnt < 10001) costpercredit = 3.5;
  else if (creditsCnt < 20001) costpercredit = 3;
  else if (creditsCnt < 30001) costpercredit = 2;
  else costpercredit = 1.5;

  return costpercredit;
};
