import React from "react";
import { Card, CardBody, Col, Progress } from "reactstrap";

const UploadCSV = ({ total, processed }) => (
  <Col md={8} xl={6} lg={6} xs={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Processing your CSV</h5>
          <p className="subhead">
            {processed} of {total} addresses are processed.
          </p>
        </div>
        <div className="progress-wrap">
          <Progress animated value={(processed / total) * 100} />
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default UploadCSV;
