import { GetAddresses, reduceCredits } from "../utils/api";
import {
  MATCH_NOT_FOUND,
  MATCH_FOUND,
  POST_CODE_NOT_FOUND,
  INVALID_POST_CODE,
} from "../utils/constants";

const BuildingPrefix = /(unit|flt|room|floor|fl|building|dept|department|apt|flat|apartment|suite|studio)/;
const CompanySuffix = /(Ltd|LTD|ltd|limited|Limited|LIMITED|PLC|Plc|plc|PUBLIC LIMITED COMPANY|Public Limited Company|public limited company|LLP|Llp|llp|L.L.P|l.l.p)/;

// Removes all punctuation from bad data
const removePunctuation = (puncstring) => {
  var punctuationless = puncstring.replace(/[.,\/#!$%\*;:{}=\_`~]/g, "");
  var finalString = punctuationless.replace(/\s{2,}/g, " ");
  return finalString;
};

const checkPostCode = (toCheck) => {
  toCheck = toCheck.replace("-", " ");
  // Permitted letters depend upon their position in the postcode.
  var alpha1 = "[abcdefghijklmnoprstuwyz]"; // Character 1
  var alpha2 = "[abcdefghklmnopqrstuvwxy]"; // Character 2
  var alpha3 = "[abcdefghjkpmnrstuvwxy]"; // Character 3
  var alpha4 = "[abehmnprvwxy]"; // Character 4
  var alpha5 = "[abdefghjlnpqrstuwxyz]"; // Character 5
  var BFPOa5 = "[abdefghjlnpqrst]"; // BFPO alpha5
  var BFPOa6 = "[abdefghjlnpqrstuwzyz]"; // BFPO alpha6

  // Array holds the regular expressions for the valid postcodes
  var pcexp = [];

  // BFPO postcodes
  pcexp.push(
    new RegExp("^(bf1)(\\s*)([0-6]{1}" + BFPOa5 + "{1}" + BFPOa6 + "{1})$", "i")
  );

  // Expression for postcodes: AN NAA, ANN NAA, AAN NAA, and AANN NAA
  pcexp.push(
    new RegExp(
      "(" +
        alpha1 +
        "{1}" +
        alpha2 +
        "?[0-9]{1,2})(\\s*)([0-9]{1}" +
        alpha5 +
        "{2})$",
      "i"
    )
  );

  // Expression for postcodes: ANA NAA
  pcexp.push(
    new RegExp(
      "(" +
        alpha1 +
        "{1}[0-9]{1}" +
        alpha3 +
        "{1})(\\s*)([0-9]{1}" +
        alpha5 +
        "{2})$",
      "i"
    )
  );

  // Expression for postcodes: AANA  NAA
  pcexp.push(
    new RegExp(
      "(" +
        alpha1 +
        "{1}" +
        alpha2 +
        "{1}" +
        "?[0-9]{1}" +
        alpha4 +
        "{1})(\\s*)([0-9]{1}" +
        alpha5 +
        "{2})$",
      "i"
    )
  );

  // Exception for the special postcode GIR 0AA
  pcexp.push(/^(GIR)(\s*)(0AA)$/i);

  // Standard BFPO numbers
  pcexp.push(/^(bfpo)(\s*)([0-9]{1,4})$/i);

  // c/o BFPO numbers
  pcexp.push(/^(bfpo)(\s*)(c\/o\s*[0-9]{1,3})$/i);

  // Overseas Territories
  pcexp.push(/^([A-Z]{4})(\s*)(1ZZ)$/i);

  // Anguilla
  pcexp.push(/^(ai-2640)$/i);

  // Load up the string to check
  var postCode = toCheck;

  // Assume we're not going to find a valid postcode
  var valid = false;

  // Check the string against the types of post codes
  for (var i = 0; i < pcexp.length; i++) {
    if (pcexp[i].test(postCode)) {
      // The post code is valid - split the post code into component parts
      pcexp[i].exec(postCode);

      // Copy it back into the original string, converting it to uppercase and inserting a space
      // between the inward and outward codes
      postCode = RegExp.$1.toUpperCase() + " " + RegExp.$3.toUpperCase();

      // If it is a BFPO c/o type postcode, tidy up the "c/o" part
      postCode = postCode.replace(/C\/O\s*/, "c/o ");

      // If it is the Anguilla overseas territory postcode, we need to treat it specially
      if (toCheck.toUpperCase() === "AI-2640") {
        postCode = "AI-2640";
      }

      // Load new postcode back into the form element
      valid = true;

      // Remember that we have found that the code is valid and break from loop
      break;
    }
  }

  // Return with either the reformatted valid postcode or the original invalid postcode
  if (valid) {
    return postCode;
  } else return false;
};

/*
 * @name: checkHouseNum
 * @function: check if the address has the house number,
 *              and then if it has, format the address using the number
 */
const checkHouseNum = async (dat, token) => {
  let wrongAddr = Object.values(dat).join(", ");
  let wrongPieces = wrongAddr.split(/[- ,]+/);

  let houseNum = [],
    buildNum = [];
  let postCode = "";

  let addressOne = "",
    addressTwo = "";

  /*
   * The last field is the post code so extract the last field
   * And then check if it's a valid post code using Regx.
   * function checkPostCode is to check the post code using Regx
   * before checking, it removes all the punctuations
   */
  for (let value of Object.values(dat)) {
    // Get post code
    if (value.length > 1) postCode = value;
  }
  // Check post code
  postCode = removePunctuation(postCode);
  postCode = checkPostCode(postCode);

  /*
   * checkPostCode returns false if the post code is invalid.
   * So if returned value is false, mark this as "unable to match",
   *             reason : "post code not found"
   */

  if (!postCode) return { status: INVALID_POST_CODE, original: wrongAddr };

  /*
   * This is when the post code is valid.
   * So it extracts all the numbers including building numbers
   * All available format of Regx is saved in BuildingPrefix variable.
   */
  for (let i = 1; i < wrongPieces.length - 1; i++) {
    if (isFinite(wrongPieces[i]) && !isNaN(parseFloat(wrongPieces[i]))) {
      // if current piece is numeric
      if (wrongPieces[i - 1].toLowerCase().match(BuildingPrefix)) {
        // When number is found and it's the building number.
        buildNum.push(wrongPieces[i]);
      } else houseNum.push(wrongPieces[i]);
    }
  }

  // When no numerica value is found, return false as it's failed
  if (!houseNum.length && !buildNum.length) return false;

  /*
   * If the houseNum is empty, use building numbers as house number
   * e.g. Unit 21 Crick Industrial Estat, Eldon Way, Crick, Notrhampton, NN6 7SL ->
   *      21 Eldon Way, Crick Industrial Estate, Crick, NORTHAMPTON, NN6 7SL
   * Unit is building prefix, but 21 is the house number.
   */
  if (!houseNum.length) {
    houseNum = buildNum;
  }
  console.log(houseNum, buildNum);
  /*
   * If the house number is found, get the address from PAF.
   * Then format the current address
   *  1. Split the address by house number.
   *  2. The first part will be Part 1 of the address.
   *  3. Concat the Part 1 with the address from PAF.
   */
  for (let i = 0; i < houseNum.length; i++) {
    try {
      let pafAddr = await GetAddresses(postCode, houseNum, token);
      addressOne = wrongAddr.split(houseNum)[0];
      // Make the string as title case
      addressOne = addressOne
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" ");
      let correctedAddr = addressOne + pafAddr;
      return {
        status: MATCH_FOUND,
        corrected: correctedAddr,
        original: wrongAddr,
      };
    } catch (err) {
      continue;
    }
  }
  return { status: MATCH_NOT_FOUND, addr: null };
};

/*
 * This function is called when the address doesn't contain the house number
 * @name: checkNoNumber
 * @function: find the house name inside the address
 *              and if found, format the address using the house name
 */
const checkNoNumber = async (dat, token) => {
  let wrongAddr = Object.values(dat).join(", ");
  let postCode = "";

  /*
   * The last field is post code so extract the last field
   * And then check if it's a valid post code using Regx.
   * function checkPostCode is to check the post code using Regx
   * before checking, it removes all the punctuations
   */
  for (let value of Object.values(dat)) {
    if (value.length > 1) postCode = value;
  }
  postCode = removePunctuation(postCode);
  postCode = checkPostCode(postCode);
  /*
   * checkPostCode returns false if the post code is invalid.
   * So if returned value is false, mark this as "unable to match",
   *             reason : "post code not found"
   */
  if (!postCode) return { isFound: INVALID_POST_CODE, original: wrongAddr };
  /*
   * This case is when the valid post code is found.
   * This function is to find the house name inside the address.
   * So it first gets all available house names of this post code from PAF.
   * Then loop the house names and check if the house name is existing inside current address.
   * There might be no, one or multiple matches.
   */
  try {
    let addresses = await GetAddresses(postCode, null, token);
    let matchFound = false;
    let availableMatch = [];
    let correctedAddr = "";
    let prevMatch = "";
    /*
     * @ Some house name includes other house name.
     * e.g House names of PL11 3DJ are :
     *       "Trerieve Farm","The Moohey","Trerieve", and "The Lower Moohey",
     * And assume that the current address is :
     *      "James Candy, Proprietor, Trerieve Organic Farm, Trerieve Farm, Plymouth, Devon, PL11 3DJ"
     * So the available house name matches are "Trerieve Farm" and "Trerieve".
     * To figure out this, I have the previous match and
     *   check if the previous match includes the current match.
     * If so, I remove this case. (So the house name "Trerieve" will be removed)
     *
     * @ There is also another case,
     *            which the house names are the same but the company names are different.
     * In this case, check if the company name matches to the current address to format.
     * If there's match, then format using this address from PAF.
     * If there's no match, then ignore the company name.
     */
    for (let i = 0; i < addresses.length; i++) {
      availableMatch.push(addresses[i].address);
      // This is the case when one house name includes the other house name
      if (wrongAddr.toLowerCase().includes(addresses[i].bname.toLowerCase())) {
        matchFound = true;
        // So when the new house name is bigger than the prev match.
        if (prevMatch.length < addresses[i].bname.length) {
          let index = wrongAddr
            .toLowerCase()
            .lastIndexOf(addresses[i].bname.toLowerCase());
          let addressOne = wrongAddr.substring(0, index);
          addressOne = addressOne
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
            .join(" ");
          correctedAddr = addressOne + addresses[i].address;
        }
      }
    }

    if (matchFound) {
      return {
        status: MATCH_FOUND,
        corrected: correctedAddr,
        original: wrongAddr,
      };
    } else {
      return {
        status: MATCH_NOT_FOUND,
        original: wrongAddr,
        possible: availableMatch,
      };
    }
  } catch (err) {
    console.log(err);
    return { status: MATCH_NOT_FOUND, original: wrongAddr, possible: [] };
  }
};

export const processAddress = async (data, setProcessedCnt, token) => {
  let success = [];
  let fail = [];
  let status = await reduceCredits(data.length, token);
  if (status !== "success") return status;

  for (let i = 0; i < data.length; i++) {
    let result = await checkHouseNum(data[i], token);
    if (result.status === MATCH_FOUND) {
      success.push({
        corrected: result.corrected,
        original: result.original,
      });
    } else if (result.status === INVALID_POST_CODE) {
      fail.push({
        original: result.original,
        reason: result.status,
        possible: [],
      });
    } else {
      // House Number not found
      result = await checkNoNumber(data[i], token);
      if (result.status === MATCH_FOUND) {
        success.push({
          corrected: result.corrected,
          original: result.original,
        });
      } else {
        // result = await checkNoClue(data[i], token);
        fail.push({
          original: result.original,
          reason: result.status,
          possible: result.possible,
        });
      }
    }

    setProcessedCnt(i + 1);
  }
  return { success, fail };
};

export const downloadSucCSV = (data) => {
  let columnsCnt = 0;
  for (let i = 0; i < data.length; i++) {
    console.log(data[i]);
    columnsCnt = Math.max(columnsCnt, data[i].corrected.split(",").length);
  }
  let header = "";
  for (let i = 0; i < columnsCnt - 2; i++) {
    header += ",";
  }
  header += ",Post Town,Post Code";
  let CSVContent =
    "data:text/csv;charset=utf-8," +
    "Name,Job,Company,PAF,PAF,PAF,Post Town,Post Code\n" +
    data
      .map((dat) => {
        console.log(dat);
        let oneRecord = [];
        let addPieces = dat.corrected.split(",");
        for (let i = 0; i < columnsCnt - 2; i++) {
          if (i < addPieces.length - 2) oneRecord.push(addPieces[i]);
          else oneRecord.push("");
        }
        oneRecord.push(addPieces[addPieces.length - 2]);
        oneRecord.push(addPieces[addPieces.length - 1]);

        return oneRecord.join(",");
      })
      .join("\n");
  let encodedUri = encodeURI(CSVContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "success.csv");
  document.body.appendChild(link);

  link.click();
};

export const downloadFailCSV = (data) => {
  let CSVContent =
    "data:text/csv;charset=utf-8," + data.map((dat) => dat.original).join("\n");
  let encodedUri = encodeURI(CSVContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "fail.csv");
  document.body.appendChild(link);

  link.click();
};
