import React, { useState } from "react";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import { Alert, Spinner, Button } from "reactstrap";

import { validateSignupForm } from "../../../utils/validate";
import { POST } from "../../../utils/api";

export default () => {
  // Represent whether form is submited and busy now.
  const [formLoading, setFormLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setFormError] = useState({
    email: "",
    password: "",
    form: "",
  });
  const [emailSent, setEmailSent] = useState(false);

  const register = async () => {
    if (formLoading) return;

    setFormLoading(true);
    setEmailSent(false);
    setFormError({ ...error, form: "" });
    let isValid = await validateSignupForm(formData, setFormError);

    if (!isValid) {
      setFormLoading(false);
      return;
    }

    POST(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/signup`, formData)
      .then((res) => res.data)
      .then((data) => {
        setFormLoading(false);
        if (data.status === "success") {
          setShowResend(true);
          setEmailSent(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setFormLoading(false);
        setFormError({ form: "Something went wrong. Please try again." });
      });
  };

  const resend = () => {
    setDisableResend(true);
    setEmailSent(false);
    POST(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/resendEmail`, {
      email: formData.email,
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.status === "success") {
          setDisableResend(false);
          setEmailSent(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setDisableResend(false);
        setFormError({ form: "Something went wrong. Please try again." });
      });
  };

  return (
    <form className="form">
      <Alert color={"danger"} isOpen={!!error.form} className="w-100">
        {error.form}
      </Alert>
      <Alert color={"success"} isOpen={!!emailSent} className="w-100">
        A verification email has been sent, please check your inbox (and junk
        folder) and click link to continue
      </Alert>
      <div className="form__form-group">
        <span className="form__form-group-label">E-mail</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AlternateEmailIcon />
          </div>
          <div className="form__form-group-input-wrap">
            <input
              type="text"
              placeholder="E-mail"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            {error.email && (
              <span className="form__form-group-error">{error.email}</span>
            )}
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>

          <div className="form__form-group-input-wrap">
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
            {error.password && (
              <span className="form__form-group-error">{error.password}</span>
            )}
          </div>
          <button
            className={`form__form-group-button${
              showPassword ? " active" : ""
            }`}
            onClick={() => setShowPassword(!showPassword)}
            type="button"
          >
            <EyeIcon />
          </button>
        </div>
      </div>

      <Button
        color="primary"
        className="account__btn account__btn--small mt-2"
        onClick={register}
        disabled={formLoading}
      >
        {formLoading && <Spinner color="primary" />}
        {formLoading === false && "Sign Up"}
      </Button>

      {showResend && (
        <div className="account__have-account w-100">
          <p>
            Didn't you receive the email?{" "}
            <a onClick={resend}>
              {disableResend && <Spinner color="primary" size="sm" />}
              {!disableResend && "Resend"}
            </a>
          </p>
        </div>
      )}
    </form>
  );
};
