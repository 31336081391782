import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";

import { Spinner } from "reactstrap";
import { authContext } from "../../contexts/AuthContext";
import { POST } from "../../utils/api";

export default (props) => {
  const { auth, setAuthData } = useContext(authContext);

  const [text, setText] = useState("");
  useEffect(() => {
    POST(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/verifyEmail`, {
      token: props.match.params.token,
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.status === "success") {
          setText("The Email is verified. Redirecting to Home...");
          setAuthData(data);
          setTimeout(() => {
            props.history.push("/pages/account");
          }, 3000);
        } else return Promise.reject("Token Invalid");
      })
      .catch((err) => {
        console.log(err);
        setText("The token is invalid. Redirecting to Login...");
        setTimeout(() => {
          props.history.push("/log_in");
        }, 3000);
      });
  }, []);
  if (auth.token) return <Redirect to="/pages" />;
  return (
    <div style={styles.container}>
      <Spinner color="primary" style={{ width: "7rem", height: "7rem" }} />
      <p>{text}</p>
    </div>
  );
};

const styles = {
  container: {
    position: "absolute",
    top: "30%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
};
