import React, { useState } from "react";
import { Alert, Button, Spinner } from "reactstrap";

import { validateForgotForm } from "../../../utils/validate";
import { POST } from "../../../utils/api";

export default () => {
  const [formLoading, setFormLoading] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
  });
  const [error, setFormError] = useState({
    email: "",
    form: "",
  });

  const send = async () => {
    setEmailSent(false);
    setFormError({ ...error, form: "" });
    if (formLoading) return;

    setFormLoading(true);
    let isValid = validateForgotForm(formData, setFormError);

    if (!isValid) {
      setFormLoading(false);
      return;
    }

    POST(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/forgotPwd`, formData)
      .then((res) => res.data)
      .then((data) => {
        setFormLoading(false);
        if (data.status === "success") {
          setShowResend(true);
          setEmailSent(true);
        } else {
          return Promise.reject(data.code);
        }
      })
      .catch((err) => {
        setFormLoading(false);
        if (err == "ERR305") setFormError({ form: "The email is not found" });
        else setFormError({ form: "Something went wrong. Please try again." });
      });
  };

  const resend = () => {
    setEmailSent(false);
    if (disableResend) return;

    setDisableResend(true);
    let isValid = validateForgotForm(formData, setFormError);

    if (!isValid) {
      setFormLoading(false);
      return;
    }

    POST(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/forgotPwd`, formData)
      .then((res) => res.data)
      .then((data) => {
        setDisableResend(false);
        if (data.status === "success") {
          return setEmailSent(true);
        }
        return Promise.reject("error");
      })
      .catch((err) => {
        setDisableResend(false);
        setFormError({ form: "Something went wrong. Please try again." });
      });
  };

  return (
    <form className="form">
      <Alert color={"danger"} isOpen={!!error.form} className="w-100">
        {error.form}
      </Alert>
      <Alert color={"success"} isOpen={emailSent} className="w-100">
        A reset email has been sent, please check your inbox and click the link
        to continue.
      </Alert>

      <div className="form__form-group">
        <div className="form__form-group-field">
          <div className="form__form-group-input-wrap">
            <input
              type="text"
              placeholder="E-mail"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            {error.email && (
              <span className="form__form-group-error">{error.email}</span>
            )}
          </div>
        </div>
      </div>

      <Button
        color="primary"
        className="account__btn account__btn--small mt-2"
        onClick={send}
        disabled={formLoading}
      >
        {formLoading && <Spinner color="primary" />}
        {formLoading === false && "Send Reset Email"}
      </Button>

      {showResend && (
        <div className="account__have-account w-100">
          <p>
            Didn't you receive the email?{" "}
            <a onClick={resend}>
              {disableResend && <Spinner color="primary" size="sm" />}
              {!disableResend && "Resend"}
            </a>
          </p>
        </div>
      )}
    </form>
  );
};
