import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { authContext } from "../../contexts/AuthContext";
import LogInForm from "./components/LogInForm";
import logo from "../../shared/img/logo/logo_light.png";

export default () => {
  const { auth } = useContext(authContext);

  if (auth.token) return <Redirect to="/pages" />;

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head d-flex">
            <div>
              <h3 className="account__title">Welcome to</h3>
              <h4 className="account__subhead subhead">
                Login to your account
              </h4>
            </div>
            <div className="site-logo">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <LogInForm />
        </div>
      </div>
    </div>
  );
};
