/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from "react";
import { Button } from "reactstrap";

import DataPaginationTable from "../../../shared/components/table/DataPaginationTable";
import Pagination from "../../../shared/components/pagination/Pagination";
import SuccessRow from "./SuccessRow";
import FailedRow from "./FailedRow";
import { downloadSucCSV, downloadFailCSV } from "../../../utils/formatAddr";

export default class DataTable extends PureComponent {
  constructor(props) {
    super(props);

    this.heads = [
      {
        key: "address",
        name: "Address",
        sortable: true,
      },
    ];

    const initialPageNumber = 1;
    const initialRowsCount = 20;
    const rowsCount = this.props.rows.length;

    const originalRows = this.props.rows;
    const currentPageRows = this.filterRows(
      originalRows,
      initialPageNumber,
      initialRowsCount
    );

    this.state = {
      rows: originalRows,
      rowsToShow: currentPageRows,
      pageOfItems: initialPageNumber,
      itemsToShow: initialRowsCount,
    };
  }

  onChangePage = (pageOfItems) => {
    const { rows, itemsToShow } = this.state;
    if (pageOfItems) {
      const rowsToShow = this.filterRows(rows, pageOfItems, itemsToShow);
      this.setState({ rowsToShow, pageOfItems });
    }
  };

  getRandomDate = (start, end) =>
    new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    ).toLocaleDateString();

  filterRows = (originalRows, pageNumber, rowsCount) => {
    const rowsFrom = rowsCount * (pageNumber - 1);
    let rowsTo = rowsFrom + rowsCount;
    let result = [];
    rowsTo = rowsTo < originalRows.length ? rowsTo : originalRows.length;

    for (let i = rowsFrom; i < rowsTo; i++) {
      result.push(originalRows[i]);
    }
    return result;
  };

  onSorting = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    };
    const { rows, pageOfItems, itemsToShow } = this.state;
    if (sortDirection !== "NONE") {
      let sortRows = [...rows].sort(comparer);
      sortRows = this.filterRows(sortRows, pageOfItems, itemsToShow);
      this.setState({ rowsToShow: sortRows });
      return sortRows;
    }
    const sortRows = this.filterRows(rows, pageOfItems, itemsToShow);
    this.setState({ rowsToShow: sortRows });
    return sortRows;
  };

  rowRenderer = ({ row, idx }) => {
    const { isSuccess } = this.props;
    if (isSuccess) {
      return <SuccessRow data={row} />;
    }
    return <FailedRow data={row} idx={idx} />;
  };

  downloadCSV = () => {
    const { rows, isSuccess } = this.props;
    if (isSuccess) downloadSucCSV(rows);
    else downloadFailCSV(rows);
  };

  componentDidUpdate(prevProps, preState) {
    if (this.props.rows !== prevProps.rows) {
      const currentPageRows = this.filterRows(this.props.rows, 1, 20);
      this.setState({
        rows: this.props.rows,
        rowsToShow: currentPageRows,
        pageOfItems: 1,
        itemsToShow: 20,
      });
    }
  }
  render() {
    const { rows, itemsToShow, pageOfItems, rowsToShow } = this.state;
    const { isSuccess } = this.props;

    return (
      <>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <h5 className="bold-text page-title mb-1">
            {isSuccess ? "Matched and Fixed Data" : "No Match Data"}
          </h5>
          <Button color="secondary" onClick={this.downloadCSV}>
            Download
          </Button>
        </div>
        <DataPaginationTable
          heads={this.heads}
          rows={rowsToShow}
          onSorting={this.onSorting}
          rowRenderer={this.rowRenderer}
        />
        <Pagination
          itemsCount={rows.length}
          itemsToShow={itemsToShow}
          pageOfItems={pageOfItems}
          onChangePage={this.onChangePage}
        />
      </>
    );
  }
}
