import axios from "axios";

const createAxios = (token) => {
  const config = {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  const instance = axios.create(config);

  return instance;
};

export const POST = (url, data, token = null) =>
  createAxios(token).post(url, data);
export const GET = (url, params, token = null) =>
  createAxios(token).get(url, { params });

export const GetAddresses = async (postcode, bnum = null, token) => {
  postcode = postcode.replace(/[\W_\s]+/g, "");
  let api_addr = `${process.env.REACT_APP_BACKEND_BASE_URL}/postcode/${postcode}`;
  if (bnum) {
    api_addr = `${process.env.REACT_APP_BACKEND_BASE_URL}/postcode/${postcode}/${bnum}`;
  }

  let { data } = await GET(api_addr, {}, token);
  if (bnum) {
    return data.address;
  }

  return data;
};

export const GetAllAddresses = async (postcode) => {
  if (postcode.length < 6) {
    return [];
  }
  postcode = postcode.replace(/[\W_\s]+/g, "");
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/postcode/${postcode}`
  );

  let addresses = [];
  for (var fa of data.addresses) {
    addresses.push({ value: fa, label: fa });
  }
  return addresses;
};

export const createIntent = async (creditsCnt, token) => {
  let api_addr = `${process.env.REACT_APP_BACKEND_BASE_URL}/user/intent`;

  let { data } = await POST(api_addr, { creditsCnt }, token);

  return data.client_secret;
};

export const subscribePlan = async (paymentMethodId, email, token) => {
  let api_addr = `${process.env.REACT_APP_BACKEND_BASE_URL}/user/subscription`;
  let { data } = await POST(
    api_addr,
    {
      payment_method: paymentMethodId,
      email: email,
    },
    token
  );
  return data.subscriptionID;
};
export const reduceCredits = async (count, token) => {
  let api_addr = `${process.env.REACT_APP_BACKEND_BASE_URL}/user/credit_reduce`;
  let { data } = await POST(api_addr, { count }, token);
  return data.status;
};

export const GetUserInfo = async (token) => {
  let api_addr = `${process.env.REACT_APP_BACKEND_BASE_URL}/user/info`;
  let data = await POST(api_addr, {}, token);
  return data;
};
