import React from "react";
import { Button, ButtonToolbar, Modal } from "reactstrap";

export default ({ modal, close, importCSV }) => {
  return (
    <div>
      <Modal
        isOpen={modal}
        modalClassName="ltr-support"
        className="modal-dialog modal-dialog--warning"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => close(false)}
          />
          <span className="lnr lnr-flag modal__title-icon" />
          <h4 className="text-modal  modal__title">Attention</h4>
        </div>
        <div className="modal__body">Does this CSV contain a header?</div>
        <ButtonToolbar className="modal__footer">
          <Button
            className="modal_ok"
            color="success"
            onClick={() => importCSV(true)}
          >
            Yes
          </Button>
          <Button className="modal_cancel" onClick={() => importCSV(false)}>
            No
          </Button>{" "}
        </ButtonToolbar>
      </Modal>
    </div>
  );
};
