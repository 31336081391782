import React, { useState, useContext, useEffect } from "react";
import { Col, Card, CardBody, Button, Alert, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import EyeIcon from "mdi-react/EyeIcon";
import Select from "react-select";

import { POST, GetAllAddresses, GetUserInfo } from "../../../utils/api";
import { authContext } from "../../../contexts/AuthContext";
import { validateProfileForm } from "../../../utils/validate";

export default () => {
  const { auth, setAuthData } = useContext(authContext);

  const [showPassword, setShowPassword] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formSucceed, setFormSucceed] = useState(false);

  let originalInfo = {
    org: "",
    fname: "",
    lname: "",
    phone: "",
    postal: "",
    address: "",
    password: "",
  };
  const [formData, setFormData] = useState({
    org: "",
    fname: "",
    lname: "",
    phone: "",
    postal: "",
    address: "",
    password: "",
  });
  const [error, setFormError] = useState({
    org: "",
    fname: "",
    lname: "",
    phone: "",
    address: "",
    postal: "",
    password: "",
    form: "",
  });
  // Address select
  const [addresses, setAddresses] = useState([]);
  // Get the list of addresses of the current postal code
  const getAddresses = async (postcode) => {
    let addresses = await GetAllAddresses(postcode);
    setAddresses(addresses);
  };

  useEffect(() => {
    GetUserInfo(auth.token)
      .then((res) => res.data.data)
      .then((data) => {
        originalInfo = data;
        if (!!data.postal) getAddresses(data.postal);
        setFormData({
          ...formData,
          org: data?.org ? data?.org : "",
          fname: data?.fname ? data?.fname : "",
          lname: data?.lname ? data?.lname : "",
          phone: data?.phone ? data?.phone : "",
          postal: data?.postal ? data?.postal : "",
          address: data?.address ? data?.address : "",
        });
        setAuthData({
          token: auth.token,
          name: data?.fname + " " + data?.lname,
          creditsRemaining: data?.creditsRemaining
        })
      })
      .catch((err) => {
        console.log(err);
        setFormData(originalInfo);
      });
  }, []);

  const update = async () => {
    setFormLoading(true);
    setFormSucceed(false);
    setFormError({ ...error, form: "" });
    let isValid = await validateProfileForm(formData, setFormError);

    if (!isValid) {
      setFormLoading(false);
      return;
    }

    POST(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/update`,
      formData,
      auth.token
    )
      .then((res) => res.data)
      .then((data) => {
        setFormLoading(false);

        if (data.status === "success") {
          setFormSucceed(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setFormError({
          form: "Something went wrong. Please try again.",
        });
      });
  };

  const cancel = () => {
    setFormData(originalInfo);
    setFormError({
      org: "",
      fname: "",
      lname: "",
      phone: "",
      address: "",
      postal: "",
      password: "",
      form: "",
    });
  };
  return (
    <Col md={12} lg={9}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">User Account</h5>
            <h5 className="subhead">Edit your details and click save</h5>
          </div>
          <form className="form form--horizontal">
            <Alert color={"danger"} isOpen={!!error.form} className="w-100">
              {error.form}
            </Alert>
            <Alert color={"success"} isOpen={formSucceed} className="w-100">
              Your profile has updated.
            </Alert>
            <div className="form__form-group">
              <span className="form__form-group-label">First Name</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="First Name"
                    onChange={(e) =>
                      setFormData({ ...formData, fname: e.target.value })
                    }
                    value={formData.fname}
                  />
                  {error.fname && (
                    <span className="form__form-group-error">
                      {error.fname}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Surname</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="Surname"
                    onChange={(e) =>
                      setFormData({ ...formData, lname: e.target.value })
                    }
                    value={formData.lname}
                  />
                  {error.lname && (
                    <span className="form__form-group-error">
                      {error.lname}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Compnay Name</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="Company name"
                    onChange={(e) =>
                      setFormData({ ...formData, org: e.target.value })
                    }
                    value={formData.org}
                  />
                  {error.org && (
                    <span className="form__form-group-error">{error.org}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Post Code</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="Post Code"
                    onChange={(e) => {
                      getAddresses(e.target.value);
                      setFormData({ ...formData, postal: e.target.value });
                    }}
                    value={formData.postal.toUpperCase()}
                  />
                  {error.postal && (
                    <span className="form__form-group-error">
                      {error.postal}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Address</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <Select
                    options={addresses}
                    className="react-select"
                    classNamePrefix="react-select"
                    placeholder="Select your address"
                    onChange={(selectedOption) => {
                      setFormData({
                        ...formData,
                        address: selectedOption.value,
                      });
                    }}
                    value={addresses.filter(
                      (option) => option.value === formData.address
                    )}
                  />
                  {error.address && (
                    <span className="form__form-group-error">
                      {error.address}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Telephone</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="Telephone"
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                    value={formData.phone}
                  />
                  {error.phone && (
                    <span className="form__form-group-error">
                      {error.phone}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Change Password</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="New password"
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                  {error.password && (
                    <span className="form__form-group-error">
                      {error.password}
                    </span>
                  )}
                </div>
                <button
                  className={`form__form-group-button${
                    showPassword ? " active" : ""
                  }`}
                  onClick={() => setShowPassword(!showPassword)}
                  type="button"
                >
                  <EyeIcon />
                </button>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Credits Remaining</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    disabled
                    value={
                      auth.creditsRemaining === -1
                        ? "Unlimited Plan"
                        : auth.creditsRemaining
                    }
                  />
                </div>
              </div>
            </div>
          </form>

          <Button color="primary" onClick={update} disabled={formLoading}>
            {formLoading && <Spinner color="primary" />}
            {formLoading === false && "Update"}
          </Button>
          <Button color="secondary" onClick={cancel}>
            Cancel
          </Button>
          {auth.creditsRemaining !== -1 && (
            <Link className="btn btn-matched" to="/pages/buy_credit">
              Buy Credits
            </Link>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
