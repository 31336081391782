import React, { useState, useContext } from "react";
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  UncontrolledTooltip,
  Tooltip,
} from "reactstrap";
import { storeContext } from "../../../contexts/StoreContext";
import { useEffect } from "react";

export default ({ idx, data }) => {
  const { store, setStoreData } = useContext(storeContext);
  // Recommended matches
  const [recommends, setRecommendMatches] = useState([]);
  // Recommended match modal
  const [recommendShow, setRecommendModal] = useState(false);
  // Show tooltip of input when the recommend is clicked
  const [inputTooltip, setInputTooltip] = useState(false);
  // Input for manual input
  const [address, setAddress] = useState("");
  // Show recommends modal
  const showRecommend = () => {
    setRecommendModal(true);
    setRecommendMatches(data.possible);
  };
  // Move this failed address to success list
  const markAsSuccess = () => {
    const failAr = Object.assign([], store.fail);
    const successAr = Object.assign([], store.success);
    successAr.push({
      corrected: address,
      original: data.original,
    });
    failAr.splice(idx, 1);
    setStoreData({ success: successAr, fail: failAr });
    setRecommendModal(false);
  };

  return (
    <>
      <Row style={styles.container}>
        <div style={styles.address}>{data.original}</div>
        <div>{data.reason}</div>
        <div style={styles.action}>
          <Button style={styles.btn} onClick={() => showRecommend()}>
            See Suggestions
          </Button>
        </div>
      </Row>

      <Modal className="suggestion" isOpen={recommendShow} backdrop="static">
        <ModalHeader toggle={() => setRecommendModal(!recommendShow)}>
          Suggestions
        </ModalHeader>
        <ModalBody style={{ textAlign: "left" }}>
          {recommends?.length > 0 && (
            <>
              <p style={{ fontSize: 18 }}>
                Here are the recommended matches. <br />
                <small>
                  <i>Click the address to copy it.</i>
                </small>
              </p>
              <ul style={styles.fitContent}>
                {recommends?.map((match, i) => {
                  return (
                    <li
                      key={match}
                      style={{ width: "fit-content" }}
                      onClick={(e) => {
                        setAddress(e.target.textContent);
                        navigator.clipboard.writeText(e.target.textContent);
                      }}
                    >
                      {match}
                    </li>
                  );
                })}
              </ul>

              <div style={styles.inputCont}>
                <p style={{ fontSize: 18 }}>Here is the original address.</p>
                <p style={{ fontSize: 15, paddingLeft: 25 }}>{data.original}</p>
                <Input
                  type="text"
                  placeholder="Address..."
                  style={styles.input}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  id="address-input"
                  value={address}
                />
                <Tooltip
                  placement="right"
                  target="address-input"
                  trigger="click"
                  toggle={() => setInputTooltip(false)}
                  isOpen={inputTooltip}
                >
                  Pasted
                </Tooltip>
              </div>
            </>
          )}
          {recommends?.length === 0 && (
            <p style={{ fontSize: 18 }}>No recommends</p>
          )}
        </ModalBody>
        <ModalFooter>
          {recommends?.length > 0 && (
            <Button color="primary" onClick={markAsSuccess}>
              Mark As Success
            </Button>
          )}
          {recommends?.length === 0 && (
            <Button
              color="secondary"
              onClick={() => setRecommendModal(!recommendShow)}
            >
              Cancel
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

const styles = {
  container: {
    alignItems: "center",
    margin: 0,
    borderBottom: "1px solid green",
    padding: "8px 15px",
  },
  address: {
    flex: 2,
  },
  action: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    padding: "5px 10px",
    fontSize: 13,
    textTransform: "none",
  },
  fitContent: {
    display: "inline-block",
    fontSize: 16,
    padding: "15px 0px 15px 35px",
  },
  input: {
    marginTop: 10,
  },
};
