/* eslint-disable consistent-return */
import React, { PureComponent } from "react";
import ReactDataGrid from "react-data-grid";
import PropTypes from "prop-types";
import { Button, Container, Row, Col } from "reactstrap";

export default class DataPaginationTable extends PureComponent {
  static propTypes = {
    heads: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        editable: PropTypes.bool,
        sortable: PropTypes.bool,
      })
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onSorting: PropTypes.func.isRequired,
    rowRenderer: PropTypes.func.isRequired,
  };

  rowGetter = (i) => {
    const { rows } = this.props;
    // return JSON.parse(JSON.stringify(rows[i]));
    const temp = Object.assign({}, rows[i]);
    return temp;
  };

  render() {
    const { heads, rows, onSorting, rowRenderer } = this.props;

    return (
      <div className="table">
        <ReactDataGrid
          // rows={rows}
          columns={heads}
          rowGetter={this.rowGetter}
          rowsCount={rows.length}
          rowHeight={44}
          minColumnWidth={100}
          onGridSort={onSorting}
          rowRenderer={rowRenderer}
        />
      </div>
    );
  }
}
