import { POST } from "../utils/api";

// Check if the email is valid
const isEmailValid = (value) => {
  var email_regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email_regx.test(String(value).toLowerCase())) return true;
  return false;
};
const errors = {
  org: {
    invalid: "Company name should be more than 3 letters",
    required: "Please input your company name.",
  },
  email: {
    invalid: "Please input the valid email address.",
    existing: "The address is already existing.",
    required: "Please input your email address.",
  },
  name: {
    invalid: "Name should be more than 3 letters",
    required: "Please input your name.",
  },
  fname: {
    invalid: "First name should be more than 3 letters",
    required: "Please input your first name.",
  },
  lname: {
    invalid: "Last name should be more than 3 letters",
    required: "Please input your last name.",
  },
  postal: {
    invalid: "Postal code should be more than 6 letters.",
    required: "Please input your postal code.",
  },
  address: {
    required: "Please select your address.",
  },
  phone: {
    invalid: "Phone number should be more than 10 letters",
    required: "Please input your phone number.",
  },
  creditNumber: {
    required: "Please input the number of credits you want to buy.",
  },
  password: {
    invalid: "Password should be more than 6 letters",
    required: "Please input your password.",
  },
  message: {
    invalid: "Message should be more than 10 letters",
    required: "Please input your message.",
  },
  terms: {
    required: "Please read and check the checkbox.",
  },
};

// Subscribe form validation
const validateField = async (name, value) => {
  const optional = ["mobile", "password"];

  if (!optional.includes(name) && value === "") {
    return errors[name]["required"];
  }

  if (
    value !== "" &&
    ((name === "org" && value.length < 3) || // Invalid org name
    (name === "name" && value.length < 3) || // Invalid name
    (name === "fname" && value.length < 3) || // Invalid first name
    (name === "lname" && value.length < 3) || // Invalid last name
    (name === "postal" && value.length < 6) || // Invalid postal code
    (name === "phone" && value.length < 10) || // Invalid phone number
    (name === "password" && value.length < 6) || // Invalid phone number
    (name === "message" && value.length < 10) || // Invalid
      (name === "email" && !isEmailValid(value))) // Invalid Email
  ) {
    return errors[name]["invalid"];
  }

  if (name === "email") {
    let { data } = await POST(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/check_email`,
      {
        email: value,
      }
    );
    return data.existing ? errors[name]["existing"] : "";
  }

  return "";
};

export const validateSignupForm = async (formData, setFormErrors) => {
  let formErrors = {};
  let isValid = true;
  for (var key of Object.keys(formData)) {
    let valid = await validateField(key, formData[key]);
    formErrors[key] = valid;
    if (valid !== "") isValid = false;
  }
  setFormErrors(formErrors);
  return isValid;
};

export const validateLoginForm = (formData, setFormErrors) => {
  let formErrors = {};
  let isValid = true;
  if (formData.email === "") {
    formErrors["email"] = errors["email"]["required"];
    isValid = false;
  } else if (isEmailValid(formData.email) === false) {
    formErrors["email"] = errors["email"]["invalid"];
    isValid = false;
  }
  if (formData.password === "") {
    formErrors["password"] = errors["password"]["required"];
    isValid = false;
  } else if (formData.password.length < 6) {
    formErrors["password"] = errors["password"]["invalid"];
    isValid = false;
  }
  setFormErrors(formErrors);
  return isValid;
};

export const validateProfileForm = async (formData, setFormErrors) => {
  let formErrors = {};
  let isValid = true;
  for (var key of Object.keys(formData)) {
    let valid = await validateField(key, formData[key]);
    formErrors[key] = valid;
    if (valid !== "") isValid = false;
  }
  setFormErrors(formErrors);
  return isValid;
};

export const validateContactForm = async (formData, setFormErrors) => {
  let formErrors = {};
  let isValid = true;
  for (var key of Object.keys(formData)) {
    let valid = await validateField(key, formData[key]);
    formErrors[key] = valid;
    if (valid !== "") isValid = false;
  }
  setFormErrors(formErrors);
  return isValid;
};

export const validateForgotForm = async (formData, setFormErrors) => {
  let formErrors = {};
  let isValid = true;
  if (formData.email === "") {
    formErrors["email"] = errors["email"]["required"];
    isValid = false;
  }
  if (!isEmailValid(formData.email)) {
    formErrors["email"] = errors["email"]["invalid"];
    isValid = false;
  }
  setFormErrors(formErrors);
  return isValid;
};

export const validateResetForm = (formData, setFormErrors) => {
  let formErrors = {};
  let isValid = true;
  if (formData.password === "") {
    formErrors["password"] = errors["email"]["required"];
    isValid = false;
  }
  if (formData.password.length < 6) {
    formErrors["password"] = errors["password"]["invalid"];
    isValid = false;
  }
  if (formData.password !== formData.cfrmpwd) {
    formErrors["cfrmpwd"] = "Password doesn't match";
    isValid = false;
  }
  setFormErrors(formErrors);
  return isValid;
};
