import React, { useState, useContext } from "react";
import { Container } from "reactstrap";
import { Redirect } from "react-router-dom";

import { authContext } from "../../contexts/AuthContext";

import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";

export default () => {
  const { auth } = useContext(authContext);
  const [creditsCnt, setCreditsCnt] = useState(1);
  const [step, setStep] = useState(1);

  if (auth.creditsRemaining === -1) return <Redirect to="/pages/upload" />;

  return (
    <Container>
      {step === 1 && (
        <Step1
          creditsCnt={creditsCnt}
          setCreditsCnt={setCreditsCnt}
          setStep={setStep}
        />
      )}
      {step === 2 && <Step2 creditsCnt={creditsCnt} setStep={setStep} />}
      {step === 3 && <Step3 creditsCnt={creditsCnt} setStep={setStep} />}
    </Container>
  );
};
