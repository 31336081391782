import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";

import ContactForm from "./components/ContactForm";

export default () => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Contact Us</h3>
          <h3 className="page-subhead subhead">
            We can't wait to hear from you.
          </h3>
        </Col>
      </Row>
      <Row>
        <ContactForm />
      </Row>
    </Container>
  );
};
