import React, { useState, useContext } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";

export default () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Instructions</h3>
        <h3 className="page-subhead subhead">How to use our system</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12} xl={11} lg={12} xs={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Watch our video</h5>
              <p className="subhead">
                Please play and learn all there is to know
              </p>
            </div>
            <div className="w-100">
              <iframe
                className="w-100"
                style={{ height: "65vh" }}
                src="https://www.youtube.com/embed/tgbNymZ7vqY"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
                title="video"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);
