import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { authContext } from "../../contexts/AuthContext";
import ForgotForm from "./components/ForgotForm";
import logo from "../../shared/img/logo/logo_light.png";

export default (props) => {
  const { auth } = useContext(authContext);

  if (auth.token) return <Redirect to="/pages/account" />;
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div>
            <div style={{ width: "33%" }}>
              <img src={logo} alt="logo" />
            </div>
            <div className="text-center my-4">
              <h3 className="font-weight-bold">Forgot Password?</h3>
              <p>
                Never mind! Please input the email you used to sign up MailWell.
              </p>
              <p>We will send a password reset email to you.</p>
            </div>
          </div>
          <ForgotForm />
        </div>
      </div>
    </div>
  );
};
