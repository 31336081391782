/* eslint-disable react/no-array-index-key */
import React, { useState, useRef } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import Papa from "papaparse";
import HeaderModal from "./HeaderModal";

const UploadCSV = ({ process }) => {
  const inputFile = useRef(null);
  // Show confirmation modal
  const [cfrmOpen, setCfrmOpen] = useState(false);
  // Indicate the CSV file
  const [csv, setCSV] = useState(null);
  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setCSV(file);
    setCfrmOpen(true);
  };
  // Import the CSV data using Papa parse
  const importCSV = (isContainHeader) => {
    // Clear previous processed addresses
    setCfrmOpen(false);

    Papa.parse(csv, {
      complete: process,
      header: isContainHeader,
      skipEmptyLines: true,
    });
  };

  return (
    <Col md={12} xl={9} lg={12} xs={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Choose your CSV</h5>
            <p className="subhead">Click to upload your CSV address file</p>
          </div>
          <Button color="primary" onClick={() => inputFile.current.click()}>
            Upload CSV
          </Button>
          <input
            accept=".csv"
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={onChangeFile}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <HeaderModal
            modal={cfrmOpen}
            close={setCfrmOpen}
            importCSV={importCSV}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default UploadCSV;
