/* eslint-disable react/no-array-index-key */
import React from "react";
import TrendingDownIcon from "mdi-react/TrendingDownIcon";
import { Card, CardBody, Col, Progress } from "reactstrap";

const TotalRecords = ({ count }) => (
  <Col md={12} lg={4}>
    <Card>
      <CardBody className="dashboard__card-widget">
        <div className="mobile-app-widget">
          <div className="mobile-app-widget__top-line mobile-app-widget__top-line--pink">
            <p className="mobile-app-widget__total-stat">{count}</p>
          </div>
          <div className="mobile-app-widget__title">
            <h5>Total Records</h5>
          </div>
          <div className="progress-wrap progress-wrap--small progress-wrap--pink-gradient progress-wrap--label-top">
            <Progress value={100}>
              <p className="progress__label">100%</p>
            </Progress>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default TotalRecords;
