/* eslint-disable react/no-array-index-key */
import React from "react";
import TrendingUpIcon from "mdi-react/TrendingUpIcon";
import { Card, CardBody, Col, Progress } from "reactstrap";

const MatchedRecords = ({ total, count }) => {
  const percentage = Number((count / total) * 100).toFixed(2);

  return (
    <Col md={12} lg={4}>
      <Card>
        <CardBody className="dashboard__card-widget">
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--lime">
              <p className="mobile-app-widget__total-stat">{count}</p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>Matched and Fixed</h5>
            </div>
            <div className="progress-wrap progress-wrap--small progress-wrap--lime-gradient progress-wrap--label-top">
              <Progress value={percentage}>
                <p className="progress__label">{percentage}%</p>
              </Progress>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default MatchedRecords;
