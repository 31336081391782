import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";

import PrivateRoute from "./components/PrivateRoute";

import VerifyEmail from "../VerifyEmail/index";
import LogIn from "../LogIn/index";
import Register from "../Register/index";
import ForgotPWD from "../ForgotPWD/index";
import ResetPWD from "../ResetPwd/index";

import Profile from "../Profile/index";
import UploadData from "../UploadData/index";
import DataDashboard from "../DataDashboard/index";
import Contact from "../Contact/index";
import PaymentStep from "../PaymentStep/index";
import Instructions from "../Instructions/index";

const Pages = () => (
  <Switch>
    <Route path="/pages/account" component={Profile} />
    <Route path="/pages/buy_credit" component={PaymentStep} />
    <Route path="/pages/contact" component={Contact} />
    <Route path="/pages/instructions" component={Instructions} />
    <Route path="/pages/upload" component={UploadData} />
    <Route path="/pages/processed" component={DataDashboard} />
    <Redirect to="/pages/account" />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <PrivateRoute path="/pages" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/verifyEmail/:token" component={VerifyEmail} />
        <Route exact path="/log_in" component={LogIn} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot_pwd" component={ForgotPWD} />
        <Route exact path="/reset_pwd/:token" component={ResetPWD} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
