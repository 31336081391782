import React, { Component } from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  logout = () => {
    const { onClick, logout } = this.props;
    onClick();
    logout();
  };
  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Account"
            icon="user"
            route="/pages/account"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Buy Credits"
            icon="cart"
            route="/pages/buy_credit"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Contact Us"
            icon="phone-handset"
            route="/pages/contact"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Instructions"
            icon="question-circle"
            route="/pages/instructions"
            onClick={this.hideSidebar}
          />
          <SidebarLink
            title="Upload Data"
            icon="upload"
            route="/pages/upload"
            onClick={this.hideSidebar}
          />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink
            title="Log Out"
            icon="exit"
            route="/log_in"
            onClick={this.logout}
          />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
