import React, { useState, useContext } from "react";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { Link } from "react-router-dom";
import { Alert, Button, Spinner } from "reactstrap";

import { authContext } from "../../../contexts/AuthContext";
import { validateLoginForm } from "../../../utils/validate";
import { POST } from "../../../utils/api";

const LogInForm = (props) => {
  const { setAuthData } = useContext(authContext);

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setFormError] = useState({
    email: "",
    password: "",
    form: "",
  });
  const [showResend, setShowResend] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const login = () => {
    setFormError({ ...error, form: "" });
    let isValid = validateLoginForm(formData, setFormError);

    if (!isValid) return;

    POST(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/login`, formData)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === "success") {
          setAuthData(data);
        } else {
          setFormError({ form: data.message });
          if (data.code === 'ERR303') {
            setShowResend(true);
          }
        }
      })
      .catch((err) => {
        setFormError({ form: "The credentials are incorrect." });
      });
  };

  const resend = () => {
    setFormError("")
    setDisableResend(true);
    setEmailSent(false);
    POST(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/resendEmail`, {
      email: formData.email,
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.status === "success") {
          setDisableResend(false);
          setEmailSent(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setDisableResend(false);
        setFormError({ form: "Something went wrong. Please try again." });
      });
  };
  
  return (
    <form className="form">
      <Alert color={"danger"} isOpen={!!error.form} className="w-100">
        {error.form}
      </Alert>
      <Alert color={"success"} isOpen={!!emailSent} className="w-100">
        A verification email has been sent, please check your inbox (and junk
        folder) and click link to continue
      </Alert>
      <div className="form__form-group">
        <span className="form__form-group-label">Email</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <div className="form__form-group-input-wrap">
            <input
              name="name"
              type="text"
              placeholder="Email"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            {error.email && (
              <span className="form__form-group-error">{error.email}</span>
            )}
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>

          <div className="form__form-group-input-wrap">
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
            {error.password && (
              <span className="form__form-group-error">{error.password}</span>
            )}
          </div>
          <button
            className={`form__form-group-button${
              showPassword ? " active" : ""
            }`}
            onClick={() => setShowPassword(!showPassword)}
            type="button"
          >
            <EyeIcon />
          </button>
        </div>
        <div className="account__forgot-password mt-2">
          <a href="/forgot_pwd">Forgot a password?</a>
        </div>
      </div>

      <Button
        color="primary"
        className="account__btn account__btn--small mt-2"
        onClick={login}
      >
        Sign In
      </Button>
      <Link
        className="btn btn-outline-primary account__btn account__btn--small"
        to="/register"
      >
        Create Account
      </Link>
      {showResend && (
        <div className="account__have-account w-100">
          <p>
            Didn't you receive the email?{" "}
            <a onClick={resend}>
              {disableResend && <Spinner color="primary" size="sm" />}
              {!disableResend && "Resend"}
            </a>
          </p>
        </div>
      )}
    </form>
  );
};

export default LogInForm;
