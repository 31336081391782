import React, { useState, useContext } from "react";
import { Col, Container, Row } from "reactstrap";

import UploadCSV from "./components/UploadCSV";
import LackCredit from "./components/LackCredit";
import ProcessingData from "./components/ProcessingData";
import { authContext } from "../../contexts/AuthContext";
import { storeContext } from "../../contexts/StoreContext";
import { processAddress } from "../../utils/formatAddr";

const UploadData = (props) => {
  // Auth Context
  const { auth, setAuthData } = useContext(authContext);
  // Store Context
  const { setStoreData } = useContext(storeContext);
  // Status represents if the processing succeeded or not
  // 0 : initial state, before processing
  // 1 : Processing now
  // 2 : Lack credits
  // 3 : Error
  const [status, setStatus] = useState(0);
  // Number of total addresses
  const [total, setTotalCnt] = useState(0);
  // Number of processed addresses
  const [processed, setProcessedCnt] = useState(0);
  // Format the address
  const processAddr = async ({ data }) => {
    setTotalCnt(data.length);
    setStatus(1);

    let res = await processAddress(data, setProcessedCnt, auth.token);

    if (res === "lack-credits") {
      setStatus(2);
      return;
    }

    if (res === "error") {
      setStatus(3);
      return;
    }

    let creditsRemaining = auth.creditsRemaining;
    if (creditsRemaining !== -1 && creditsRemaining !== 0)
      creditsRemaining -= data.length;

    setAuthData({
      ...auth,
      creditsRemaining: creditsRemaining,
    });

    setStoreData(res);
    props.history.push("/pages/processed");
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Upload and format data</h3>
          <h3 className="page-subhead subhead">
            Select your CSV file and upload to cleanse and update your address
            data to match the PAF
          </h3>
        </Col>
      </Row>
      <Row>
        <UploadCSV process={processAddr} />
        {status === 1 && <ProcessingData total={total} processed={processed} />}
        {status === 2 && <LackCredit total={total} />}
      </Row>
    </Container>
  );
};

export default UploadData;
