import React, { useContext } from "react";
import Scrollbar from "react-smooth-scrollbar";
import classNames from "classnames";
import PropTypes from "prop-types";
import SidebarContent from "./SidebarContent";
import { authContext } from "../../../contexts/AuthContext";

const Sidebar = ({
  changeMobileSidebarVisibility,
  sidebarShow,
  sidebarCollapse,
}) => {
  const { auth, setAuthData } = useContext(authContext);

  const sidebarClass = classNames({
    sidebar: true,
    "sidebar--show": sidebarShow,
    "sidebar--collapse": sidebarCollapse,
  });

  const logout = () => {
    changeMobileSidebarVisibility();
    setAuthData({ token: "", name: "", creditsRemaining: 0 });
  };
  return (
    <div className={sidebarClass}>
      <button
        type="button"
        className="sidebar__back"
        onClick={changeMobileSidebarVisibility}
      />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent onClick={() => {}} logout={logout} />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            onClick={changeMobileSidebarVisibility}
            logout={logout}
          />
        </div>
      </Scrollbar>
    </div>
  );
};

Sidebar.propTypes = {
  sidebarShow: PropTypes.bool.isRequired,
  sidebarCollapse: PropTypes.bool.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
