import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import { Redirect } from "react-router-dom";

import UploadCSV from "./components/UploadCSV";
import TotalRecordsShort from "./components/TotalRecordsShort";
import MatchedRecordsShort from "./components/MatchedRecordsShort";
import FailedRecordsShort from "./components/FailedRecordsShort";
import ResultRecords from "./components/ResultRecords";
import { storeContext } from "../../contexts/StoreContext";

const DataDashboard = () => {
  const { store } = useContext(storeContext);

  if (store.success.length === 0 && store.fail.length === 0)
    return <Redirect to="/pages/upload" />;

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Data Dashboard</h3>
          <h3 className="page-subhead subhead">
            Your data has been processed and can be viewed below
          </h3>
        </Col>
      </Row>
      <Row>
        <UploadCSV />
      </Row>
      <Row>
        <TotalRecordsShort count={store.success.length + store.fail.length} />
        <MatchedRecordsShort
          total={store.success.length + store.fail.length}
          count={store.success.length}
        />
        <FailedRecordsShort
          total={store.success.length + store.fail.length}
          count={store.fail.length}
        />
      </Row>
      <Row>
        <ResultRecords />
      </Row>
    </Container>
  );
};
export default DataDashboard;
