import React from "react";
import { Button, ButtonToolbar, Modal } from "reactstrap";

export default ({ isOpen, title, color, message, onClick }) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        modalClassName="ltr-support"
        className="modal-dialog modal-dialog--warning"
      >
        <div className="modal__header">
          {/* <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={this.toggle}
          /> */}
          <span className="lnr lnr-thumbs-up modal__title-icon" />
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div className="modal__body">{message}</div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_ok" color={color} onClick={onClick}>
            Ok
          </Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};
