import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";

import { authContext } from "../../contexts/AuthContext";
import RegisterForm from "./components/RegisterForm";
import logo from "../../shared/img/logo/logo_light.png";

export default () => {
  const { auth } = useContext(authContext);

  if (auth.token) return <Redirect to="/pages/upload" />;
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head d-flex">
            <div>
              <h3 className="account__title">Welcome to</h3>
              <h4 className="account__subhead subhead">Create your account</h4>
            </div>

            <div className="site-logo">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <RegisterForm />
          <div className="account__have-account">
            <p>
              Already have an account? <Link to="/log_in">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
