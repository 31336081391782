/* eslint-disable react/no-array-index-key */
import React, { useContext } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { authContext } from "../../../contexts/AuthContext";

export default ({ total }) => {
  const { auth } = useContext(authContext);

  return (
    <Col md={12} xl={9} lg={12} xs={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text text-danger">Attention</h5>
            <p className="subhead">
              You have uploaded {total} but only have {auth.creditsRemaining}{" "}
              credits. Please{" "}
              <Link to="/pages/buy_credit">buy more credits</Link>
            </p>
          </div>
          <Link className="btn btn-matched" to="/pages/buy_credit">
            Buy Credits
          </Link>
          <Button color="secondary">Cancel</Button>
        </CardBody>
      </Card>
    </Col>
  );
};
