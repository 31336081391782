import React, { createContext, useState, useEffect } from "react";

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    loading: true,
    token: null,
    name: null,
    creditsRemaining: 0,
  });

  const setAuthData = (data) => {
    setAuth({
      token: data.token,
      name: data.name,
      creditsRemaining: data.creditsRemaining,
    });
  };

  useEffect(() => {
    setAuth({
      loading: false,
      token: JSON.parse(window.localStorage.getItem("mailwell-auth"))?.token,
      name: JSON.parse(window.localStorage.getItem("mailwell-auth"))?.name,
      creditsRemaining: JSON.parse(window.localStorage.getItem("mailwell-auth"))
        ?.creditsRemaining,
    });
  }, []);

  useEffect(() => {
    window.localStorage.setItem("mailwell-auth", JSON.stringify(auth));
  }, [auth]);

  return (
    <authContext.Provider value={{ auth, setAuthData }}>
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;
