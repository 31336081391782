/* eslint-disable react/no-array-index-key */
import React, { useContext } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { authContext } from "../../../contexts/AuthContext";

const UploadCSV = () => {
  const { auth } = useContext(authContext);

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Choose your CSV</h5>
            <p className="subhead">
              You have{" "}
              {auth.creditsRemaining === -1
                ? "unlimited"
                : auth.creditsRemaining}{" "}
              credits remaining
            </p>
          </div>
          <Link className="btn btn-primary" to="/pages/upload">
            Upload CSV
          </Link>
          <Link className="btn btn-matched" to="/pages/buy_credit">
            Buy Credits
          </Link>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UploadCSV;
