import React, { useState, useContext } from "react";
import { Col, Row, Card, CardBody, Button, Spinner } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";

import { authContext } from "../../../contexts/AuthContext";

import Modal from "./Modal";
import { POST } from "../../../utils/api";
import { formatNumber, getCosePerCredit } from "../../../utils/helper";

export default ({ creditsCnt, setCreditsCnt, setStep }) => {
  const costpercredit = getCosePerCredit(creditsCnt);

  const { auth, setAuthData } = useContext(authContext);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const proceed = () => {
    if (costpercredit > 0) {
      setStep(2);
      return;
    }
    POST(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/add_credit`,
      { creditsCnt },
      auth.token
    )
      .then((res) => {
        setLoading(false);
        if (res.data.status === "success") {
          setSuccess(true);
          setAuthData({
            ...auth,
            creditsRemaining:
              parseInt(auth.creditsRemaining) + parseInt(creditsCnt),
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Buy Credits</h3>
          <h3 className="page-subhead subhead">
            Please enter the amount of credits you wish to purchase
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={9}>
          <Card>
            <CardBody>
              <Row>
                <Col md={5} lg={4} className="px-4">
                  <div className="card__title mb-2">
                    <h5 className="bold-text">Credits</h5>
                    <h5 className="subhead">Pricing</h5>
                  </div>

                  <div className="payment">
                    <h5 className="text-secondary">Number of credits</h5>
                    <h5 className="text-secondary">Cost per credit</h5>
                  </div>

                  <div className="payment">
                    <h5>0 - 30</h5>
                    <h5>Free</h5>
                  </div>

                  <div className="payment">
                    <h5>up to 1000 credits</h5>
                    <h5>5p</h5>
                  </div>

                  <div className="payment">
                    <h5>up to 3000 credits</h5>
                    <h5>4.5p</h5>
                  </div>

                  <div className="payment">
                    <h5>up to 5000 credits</h5>
                    <h5>4p</h5>
                  </div>

                  <div className="payment">
                    <h5>up to 10,000 credits</h5>
                    <h5>3.5p</h5>
                  </div>

                  <div className="payment">
                    <h5>up to 20,000 credits</h5>
                    <h5>3p</h5>
                  </div>

                  <div className="payment">
                    <h5>up to 30,000 credits</h5>
                    <h5>2p</h5>
                  </div>

                  <div className="payment">
                    <h5>Over 30,000 credits</h5>
                    <h5>1.5p</h5>
                  </div>
                </Col>
                <Col md={5} lg={4} className="px-4">
                  <div className="card__title mt-3 mb-2">
                    <h5 className="bold-text">ENTER NUMBER OF CREDTIS</h5>
                  </div>
                  <TextField
                    className="w-100"
                    type="numer"
                    placeholder="Number of credits"
                    onChange={(e) => setCreditsCnt(e.target.value)}
                    value={creditsCnt}
                  />
                  <div className="card__title mt-3 mb-2">
                    <h5 className="bold-text">Summary</h5>
                    <h5 className="subhead">Your total price</h5>
                  </div>

                  <div className="payment">
                    <h5>Credits</h5>
                    <h5>{formatNumber(creditsCnt)}</h5>
                  </div>

                  <div className="payment">
                    <h5>Cost per credit</h5>
                    <h5>{costpercredit}p</h5>
                  </div>
                  <div className="payment">
                    <h5>Total Price</h5>
                    <h5>
                      £{Number((costpercredit * creditsCnt) / 100).toFixed(2)}
                    </h5>
                  </div>
                  <div className="payment">
                    <h5>VAT @ 20%</h5>
                    <h5>
                      £
                      {Number((costpercredit * creditsCnt * 0.2) / 100).toFixed(
                        2
                      )}
                    </h5>
                  </div>
                  <div className="payment">
                    <h5>Total Cost</h5>
                    <h5>
                      £
                      {Number((costpercredit * creditsCnt * 1.2) / 100).toFixed(
                        2
                      )}
                    </h5>
                  </div>

                  <div>
                    <Button
                      color="matched"
                      className="w-100 mt-2"
                      onClick={proceed}
                    >
                      {costpercredit > 0 && "Proceed to payment"}
                      {costpercredit === 0 &&
                        loading === false &&
                        "Get Credits"}
                      {costpercredit === 0 && loading && (
                        <Spinner color="primary" />
                      )}
                    </Button>
                  </div>
                </Col>
                <Col md={5} lg={4} className="px-4">
                  <div className="card__title mt-3 mb-2">
                    <h5 className="bold-text">Subscribe</h5>
                    <h5 className="subhead" style={{ fontSize: 13 }}>
                      For high volume users and mailing houses an annual
                      unlimited subscription is available please{" "}
                      <Link to="/pages/contact">contact us</Link> for more
                      details
                    </h5>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        color="success"
        title="Success!"
        btn="Success"
        message="Thank you, your payment was successful."
        isOpen={success}
        onClick={() => {
          setSuccess(false);
        }}
      />
    </>
  );
};
