import React, { useState } from "react";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import { Alert, Spinner, Button } from "reactstrap";

import { validateResetForm } from "../../../utils/validate";
import { POST } from "../../../utils/api";

export default ({ token }) => {
  // Represent whether form is submited and busy now.
  const [formLoading, setFormLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCfrmPwd, setShowCfrmPwd] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const [formData, setFormData] = useState({
    password: "",
    cfrmpwd: "",
  });
  const [error, setFormError] = useState({
    password: "",
    cfrmpwd: "",
    form: "",
  });

  const reset = async () => {
    setFormLoading(true);
    setResetSuccess(false);
    setFormError({ ...error, form: "" });
    let isValid = validateResetForm(formData, setFormError);

    if (!isValid) {
      setFormLoading(false);
      return;
    }

    POST(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/resetPwd`, {
      token: token,
      password: formData.password,
    })
      .then((res) => res.data)
      .then((data) => {
        setFormLoading(false);
        if (data.status === "success") {
          setResetSuccess(true);
        }
      })
      .catch((err) => {
        setFormLoading(false);
        console.log(err);
        setFormError({ form: "The token is invalid." });
      });
  };

  return (
    <form className="form">
      <Alert color={"danger"} isOpen={!!error.form} className="w-100">
        {error.form}
      </Alert>
      <Alert color={"success"} isOpen={resetSuccess} className="w-100">
        The password has been reseted. Please go to sign in.
      </Alert>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Password <span className="text-danger">*</span>
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>

          <div className="form__form-group-input-wrap">
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
            {error.password && (
              <span className="form__form-group-error">{error.password}</span>
            )}
          </div>
          <button
            className={`form__form-group-button${
              showPassword ? " active" : ""
            }`}
            onClick={() => setShowPassword(!showPassword)}
            type="button"
          >
            <EyeIcon />
          </button>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Confirm Password <span className="text-danger">*</span>
        </span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>

          <div className="form__form-group-input-wrap">
            <input
              name="password"
              type={showCfrmPwd ? "text" : "password"}
              placeholder="Confirm Password"
              onChange={(e) =>
                setFormData({ ...formData, cfrmpwd: e.target.value })
              }
            />
            {error.cfrmpwd && (
              <span className="form__form-group-error">{error.cfrmpwd}</span>
            )}
          </div>
          <button
            className={`form__form-group-button${showCfrmPwd ? " active" : ""}`}
            onClick={() => setShowCfrmPwd(!showCfrmPwd)}
            type="button"
          >
            <EyeIcon />
          </button>
        </div>
      </div>

      <Button
        color="primary"
        className="account__btn account__btn--small mt-2"
        onClick={reset}
        disabled={formLoading}
      >
        {formLoading && <Spinner color="primary" />}
        {formLoading === false && "Reset"}
      </Button>
    </form>
  );
};
