/* eslint-disable react/no-array-index-key */
import React, { useContext, useState, useEffect } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import DataTable from "./DataTable";
import { storeContext } from "../../../contexts/StoreContext";

const ResultRecords = () => {
  const { store } = useContext(storeContext);
  const [showSuc, setShowSuc] = useState(true);

  return (
    <Col md={12}>
      <Card>
        <CardBody className="results">
          <div className="card__title">
            <h5 className="bold-text">View and download your results</h5>
            <p className="subhead">
              Select from the options below to view and download your results
            </p>
          </div>
          <Button color="matched" onClick={() => setShowSuc(true)}>
            View fixed and matched data
          </Button>
          <Button color="no-match" onClick={() => setShowSuc(false)}>
            View no match possible
          </Button>
          {showSuc && <DataTable isSuccess={true} rows={store.success} />}
          {!showSuc && <DataTable isSuccess={false} rows={store.fail} />}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ResultRecords;
