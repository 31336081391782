import React, { createContext, useState } from "react";

export const storeContext = createContext({});

const StoreProvider = ({ children }) => {
  const [store, setStore] = useState({
    success: [],
    fail: [],
  });

  const setStoreData = (data) => {
    setStore({
      success: data.success,
      fail: data.fail,
    });
  };

  return (
    <storeContext.Provider value={{ store, setStoreData }}>
      {children}
    </storeContext.Provider>
  );
};

export default StoreProvider;
