import React, { useState, useContext } from "react";
import { Col, Card, CardBody, Button, Alert, Spinner } from "reactstrap";

import { POST } from "../../../utils/api";
import { authContext } from "../../../contexts/AuthContext";
import { validateContactForm } from "../../../utils/validate";

export default () => {
  const { auth } = useContext(authContext);

  const [formLoading, setFormLoading] = useState(false);
  const [formSucceed, setFormSucceed] = useState(false);

  const [formData, setFormData] = useState({
    org: "",
    name: "",
    mobile: "",
    message: "",
  });
  const [error, setFormError] = useState({
    org: "",
    name: "",
    phone: "",
    message: "",
    form: "",
  });

  const submit = async () => {
    setFormLoading(true);
    setFormError({ ...error, form: "" });
    setFormSucceed(false);
    let isValid = await validateContactForm(formData, setFormError);

    if (!isValid) {
      setFormLoading(false);
      return;
    }

    POST(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/contact`,
      formData,
      auth.token
    )
      .then((res) => res.data)
      .then((data) => {
        setFormLoading(false);

        if (data.status === "success") {
          setFormSucceed(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setFormLoading(false);
        setFormError({
          form: "Something went wrong. Please try again.",
        });
      });
  };

  const cancel = () => {
    setFormData({
      org: "",
      name: "",
      phone: "",
      message: "",
    });
    setFormError({
      org: "",
      name: "",
      phone: "",
      message: "",
      form: "",
    });
  };

  return (
    <Col md={12} lg={9}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Submit your enquiry</h5>
            <h5 className="subhead">Use the form to get in touch.</h5>
          </div>
          <form className="form form--horizontal">
            <Alert color={"danger"} isOpen={!!error.form} className="w-100">
              {error.form}
            </Alert>
            <Alert color={"success"} isOpen={formSucceed} className="w-100">
              Your message has been submitted.
            </Alert>
            <div className="form__form-group">
              <span className="form__form-group-label">Full Name</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="Full Name"
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    value={formData.name}
                  />
                  {error.name && (
                    <span className="form__form-group-error">{error.name}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Mobile Number</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    onChange={(e) =>
                      setFormData({ ...formData, mobile: e.target.value })
                    }
                    value={formData.lname}
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Company Name</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input
                    type="text"
                    placeholder="Company name"
                    onChange={(e) =>
                      setFormData({ ...formData, org: e.target.value })
                    }
                    value={formData.org}
                  />
                  {error.org && (
                    <span className="form__form-group-error">{error.org}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Message</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <textarea
                    type="text"
                    placeholder="Message"
                    onChange={(e) => {
                      setFormData({ ...formData, message: e.target.value });
                    }}
                    value={formData.message}
                  />
                  {error.message && (
                    <span className="form__form-group-error">
                      {error.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </form>
          <Button color="primary" onClick={submit} disabled={formLoading}>
            {formLoading && <Spinner color="primary" />}
            {formLoading === false && "Send"}
          </Button>
          <Button color="secondary" onClick={cancel}>
            Cancel
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
};
