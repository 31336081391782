import React, { useContext } from "react";
import { PayPalButton } from "react-paypal-button-v2";

import { authContext } from "../../../contexts/AuthContext";
import { POST } from "../../../utils/api";
import { getCosePerCredit } from "../../../utils/helper";

export default ({ creditsCnt, setSuccess, setError }) => {
  const { auth, setAuthData } = useContext(authContext);
  const costpercredit = getCosePerCredit(creditsCnt);

  return (
    <PayPalButton
      amount={(costpercredit * creditsCnt * 1.2) / 100}
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        vault: true,
        disableFunding: "card,credit",
        currency: "GBP",
      }}
      onSuccess={(details, data) => {
        POST(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/user/add_credit`,
          { creditsCnt },
          auth.token
        )
          .then((res) => {
            if (res.data.status === "success") {
              setSuccess(true);
              setAuthData({
                ...auth,
                creditsRemaining:
                  parseInt(auth.creditsRemaining) + parseInt(creditsCnt),
              });
            }
          })
          .catch((err) => {
            setError(err.message);
          });
      }}
      onError={(err) => {
        console.log(err);
        setError(err.message);
      }}
    />
  );
};
