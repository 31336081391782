import React, { useState, useContext } from "react";
import { Col, Row, Card, CardBody, Button, Alert } from "reactstrap";

import { authContext } from "../../../contexts/AuthContext";
import PaypalButton from "./PaypalButton";
import Modal from "./Modal";
import { formatNumber, getCosePerCredit } from "../../../utils/helper";

export default ({ creditsCnt, setStep }) => {
  const { auth, setAuthData } = useContext(authContext);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const costpercredit = getCosePerCredit(creditsCnt);

  return (
    <>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Choose your Payment Method</h3>
          <h3 className="page-subhead subhead">
            Pay securely by credit card or PayPal
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={9}>
          <Card>
            <CardBody>
              <div className="card__title mb-2">
                <h5 className="bold-text">Buy Credits</h5>
                <h5 className="subhead">Select your payment method</h5>
              </div>
              {!!error && (
                <Alert color="danger" style={{ maxWidth: 500 }}>
                  {error}
                </Alert>
              )}
              <Modal
                color="success"
                title="Success!"
                btn="Success"
                message="Thank you, your payment was successful."
                isOpen={success}
                onClick={() => {
                  setSuccess(false);
                  setAuthData({
                    ...auth,
                    status: "active",
                    maxVenues: creditsCnt,
                  });
                }}
              />
              <Col md={6} lg={5} className="px-4">
                <div className="card__title mb-2">
                  <h5 className="bold-text">Summary</h5>
                  <h5 className="subhead">Your total price</h5>
                </div>

                <div className="payment">
                  <h5>{formatNumber(creditsCnt)} Credits</h5>
                </div>

                <div className="payment">
                  <h5>Cost per credit</h5>
                  <h5>{costpercredit}p</h5>
                </div>
                <div className="payment">
                  <h5>PRICE</h5>
                  <h5>
                    £{Number((costpercredit * creditsCnt) / 100).toFixed(2)}
                  </h5>
                </div>
                <div className="payment">
                  <h5>VAT @ 20%</h5>
                  <h5>
                    £
                    {Number((costpercredit * creditsCnt * 0.2) / 100).toFixed(
                      2
                    )}
                  </h5>
                </div>
                <div className="payment">
                  <h5>TOTAL COST</h5>
                  <h5>
                    £
                    {Number((costpercredit * creditsCnt * 1.2) / 100).toFixed(
                      2
                    )}
                  </h5>
                </div>

                <Row>
                  <Col md={12}>
                    <Button
                      color="matched"
                      className="w-100 mt-2 font-weight-bold"
                      onClick={() => setStep(3)}
                    >
                      <img
                        src={require("../../../shared/img/card.png")}
                        style={{ maxHeight: 30, width: "auto" }}
                      />{" "}
                      Credit Card
                    </Button>
                  </Col>
                  <Col md={12}>
                    <PaypalButton
                      creditsCnt={creditsCnt}
                      setSuccess={setSuccess}
                      setError={setError}
                    />
                  </Col>
                </Row>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
